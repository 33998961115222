import React from "react";
import { useTranslation } from "react-i18next";
import dataFooterMenu from "../../data/footer/footerMenu";

const FooterMenu = () => {
  const { t } = useTranslation();
  return (
    <nav className="menu-secondary">
      <ul className="clearfix list-unstyled">
        {dataFooterMenu &&
          dataFooterMenu.map((item, key) => {
            return (
              <li key={key}>
                <a
                  title={t(item.title)}
                  className="btn btn-link transform-scale-h border-0 p-0"
                  href={item.link}
                >
                  {t(item.title)}
                </a>
              </li>
            );
          })}
      </ul>
    </nav>
  );
};

export default FooterMenu;
