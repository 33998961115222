import React, { Fragment } from "react";
import MetaTags from "react-meta-tags";

import { useTranslation } from "react-i18next";
import Footer from "../blocks/footer/Footer";
import Header from "../blocks/header/Header";
import Loading from "../blocks/loading/Loading";

const GingersWorld = () => {
  document.body.classList.add("single");
  document.body.classList.add("single-portfolio");
  document.body.classList.add("bg-fixed");
  document.body.classList.add("bg-line");
  const { t } = useTranslation();
  return (
    <Fragment>
      <MetaTags>
        <meta charSet="UTF-8" />
        <title>Ginger's World | Jessica Mondini</title>

        <meta httpEquiv="x-ua-compatible" content="ie=edge" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="description" content="" />
        <meta name="keywords" content="" />
        <meta name="robots" content="index, follow, noodp" />
        <meta name="googlebot" content="index, follow" />
        <meta name="google" content="notranslate" />
        <meta name="format-detection" content="telephone=no" />
      </MetaTags>

      <Loading />

      <Header />

      <main id="main" className="site-main bg-half-ring-right bg-half-ring-top">
        <section id="page-content">
          <div className="wrapper">
            <div id="single">
              <div className="row gutter-width-lg single-content">
                <div className="col-xl-7 col-lg-7 col-md-7 col-sm-12">
                  <div className="img object-fit">
                    <div className="object-fit-cover">
                      <img
                        src="assets/img/MockupSito.jpg"
                        alt="General Mockup Website"
                      />
                    </div>
                  </div>

                  <div className="img object-fit">
                    <div className="object-fit-cover">
                      <img
                        src="assets/img/GingersDesktop.jpg"
                        alt="Website Desktop"
                      />
                    </div>
                  </div>

                  <div className="img object-fit">
                    <div className="object-fit-cover">
                      <img
                        src="assets/img/GingersMobile.jpg"
                        alt="Website Mobile"
                      />
                    </div>
                  </div>
                </div>

                <div className="col-xl-5 col-lg-5 col-md-5 col-sm-12">
                  <p className="date">
                    <span className="large">25</span> {t("ginger.may")}, 2020
                  </p>

                  <h1 className="small">{t("ginger.name")}</h1>

                  <div className="description">
                    {[...Array(5).keys()].map((e) => (
                      <>
                        <h5>{t(`beautiful.content.${e}.title`)}</h5>
                        <p>{t(`beautiful.content.${e}.body`)}</p>
                      </>
                    ))}
                    <ul>
                      <li>
                        <a
                          title="Desktop Link"
                          className="btn btn-link border-0 p-0 transform-scale-h"
                          href="https://gingersworld.invisionapp.com/public/share/ME14FWNU8C"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Desktop Link
                        </a>
                      </li>

                      <li>
                        <a
                          title="Mobile Link"
                          className="btn btn-link border-0 p-0 transform-scale-h"
                          href="https://gingersworld.invisionapp.com/public/share/VZ14FRYNF2#screens/477585591"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Mobile link
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>

      <Footer />
    </Fragment>
  );
};

export default GingersWorld;
