import React, { Fragment } from "react";
import MetaTags from "react-meta-tags";

import { useTranslation } from "react-i18next";
import Footer from "../blocks/footer/Footer";
import Header from "../blocks/header/Header";
import Loading from "../blocks/loading/Loading";

const Armonia = () => {
  const { t } = useTranslation();

  document.body.classList.add("single");
  document.body.classList.add("single-portfolio");
  document.body.classList.add("bg-fixed");
  document.body.classList.add("bg-line");

  return (
    <Fragment>
      <MetaTags>
        <meta charSet="UTF-8" />
        <title>Àrmonia | Jessica Mondini</title>

        <meta httpEquiv="x-ua-compatible" content="ie=edge" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="description" content="" />
        <meta name="keywords" content="" />
        <meta name="robots" content="index, follow, noodp" />
        <meta name="googlebot" content="index, follow" />
        <meta name="google" content="notranslate" />
        <meta name="format-detection" content="telephone=no" />
      </MetaTags>

      <Loading />

      <Header />

      <main id="main" className="site-main bg-half-ring-right bg-half-ring-top">
        <section id="page-content">
          <div className="wrapper">
            <div id="single">
              <div className="row gutter-width-lg single-content">
                <div className="col-xl-7 col-lg-7 col-md-7 col-sm-12">
                  <div className="img object-fit">
                    <div className="object-fit-cover">
                      <img src="assets/img/FedeView.png" alt="App" />
                    </div>
                  </div>

                  <div className="img object-fit">
                    <div className="object-fit-cover">
                      <img
                        src="assets/img/OpzioniLogo-02.png"
                        alt="Other logo"
                      />
                    </div>
                  </div>

                  <div className="img object-fit">
                    <div className="object-fit-cover">
                      <img
                        src="assets/img/Logo.png"
                        style={{ objectFit: "scale-down", padding: "38px" }}
                        alt="Main logo"
                      />
                    </div>
                  </div>
                </div>

                <div className="col-xl-5 col-lg-5 col-md-5 col-sm-12">
                  <p className="date">
                    <span className="large">16</span> {t("armonia.november")},
                    2020
                  </p>

                  <h1 className="small">Àrmonia</h1>

                  <div className="description">
                    {[...Array(5).keys()].map((e) => (
                      <>
                        <h5>{t(`armonia.content.${e}.title`)}</h5>
                        {e === 2 ? (
                          <ul>
                            {[...Array(4).keys()].map((i) => (
                              <li style={{ lineHeight: "1.75em" }}>
                                <strong>
                                  {t(`armonia.content.${e}.items.${i}.title`)}:{" "}
                                </strong>
                                {t(`armonia.content.${e}.items.${i}.body`)}
                              </li>
                            ))}
                          </ul>
                        ) : (
                          <p>{t(`armonia.content.${e}.body`)}</p>
                        )}
                      </>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>

      <Footer />
    </Fragment>
  );
};

export default Armonia;
