import React, { Fragment } from "react";
import MetaTags from "react-meta-tags";

import { useTranslation } from "react-i18next";
import ReactPlayer from "react-player";
import Footer from "../blocks/footer/Footer";
import Header from "../blocks/header/Header";
import Loading from "../blocks/loading/Loading";

const GiroAlSas = () => {
  const { t } = useTranslation();
  document.body.classList.add("single");
  document.body.classList.add("single-portfolio");
  document.body.classList.add("bg-fixed");
  document.body.classList.add("bg-line");
  return (
    <Fragment>
      <MetaTags>
        <meta charSet="UTF-8" />
        <title>Giro Al Sas | Jessica Mondini</title>

        <meta httpEquiv="x-ua-compatible" content="ie=edge" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="description" content="" />
        <meta name="keywords" content="" />
        <meta name="robots" content="index, follow, noodp" />
        <meta name="googlebot" content="index, follow" />
        <meta name="google" content="notranslate" />
        <meta name="format-detection" content="telephone=no" />
      </MetaTags>

      <Loading />

      <Header />

      <main id="main" className="site-main bg-half-ring-right bg-half-ring-top">
        <section id="page-content">
          <div className="wrapper">
            <div id="single">
              <div className="row gutter-width-lg single-content">
                <div className="col-xl-7 col-lg-7 col-md-7 col-sm-12">
                  <ReactPlayer
                    url="../../assets/video/PresentazioneAlSas.mp4"
                    controls={true}
                    className="react-player"
                    width="100%"
                    height="auto"
                    config={{
                      file: {
                        attributes: {
                          style: {
                            display: "block",
                            width: "100%",
                            height: "auto",
                            marginBottom: "50px",
                          },
                        },
                      },
                    }}
                  />

                  <div className="img object-fit">
                    <div className="object-fit-cover">
                      <img
                        src="assets/img/GiroAlSasMerch.jpg"
                        alt="Merch Al Sas"
                      />
                    </div>
                  </div>

                  <div className="img object-fit">
                    <div className="object-fit-cover">
                      <img
                        src="assets/img/CoffeeAlSas.jpg"
                        alt="Coffee Al Sas"
                      />
                    </div>
                  </div>
                </div>

                <div className="col-xl-5 col-lg-5 col-md-5 col-sm-12">
                  <p className="date">
                    <span className="large">26</span> {t("giroalsas.june")} ,
                    2020
                  </p>

                  <h1 className="small">Giro Al Sas</h1>

                  <div className="description">
                    {[...Array(9).keys()].map((e) => (
                      <Part index={e} />
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>

      <Footer />
    </Fragment>
  );
};

function Part({ index }) {
  const { t } = useTranslation();
  if (index === 1) {
    return <h4>{t("giroalsas.content.1.title")}</h4>;
  }

  if (index === 4) {
    return (
      <>
        <h5>{t("giroalsas.content.4.title")}</h5>
        <ul>
          {[...Array(2).keys()].map((i) => (
            <li style={{ lineHeight: "1.75em" }}>
              <strong>{t(`giroalsas.content.4.items.${i}.title`)}: </strong>
              {t(`giroalsas.content.4.items.${i}.body`)}
            </li>
          ))}
        </ul>
      </>
    );
  }

  if (index === 7) {
    return (
      <>
        <h5>{t("giroalsas.content.7.title")}</h5>
        <ul>
          {[...Array(3).keys()].map((i) => (
            <li style={{ lineHeight: "1.75em" }}>
              <strong>{t(`giroalsas.content.7.items.${i}.title`)}: </strong>
              {t(`giroalsas.content.7.items.${i}.body`)}
            </li>
          ))}
        </ul>
      </>
    );
  }

  if (index === 0)
    return (
      <>
        <h4>{t(`giroalsas.content.0.title`)}</h4>
        <p>{t(`giroalsas.content.0.body`)}</p>
      </>
    );

  return (
    <>
      <h5>{t(`giroalsas.content.${index}.title`)}</h5>
      <p>{t(`giroalsas.content.${index}.body`)}</p>
    </>
  );
}

export default GiroAlSas;
